/**
 * @generated SignedSource<<81a29d121f7be69e95cc61a675aabe7f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserEditFormRefetchQuery$variables = {
  id: string;
};
export type UserEditFormRefetchQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"UserEditFormFragment">;
  };
};
export type UserEditFormRefetchQuery = {
  response: UserEditFormRefetchQuery$data;
  variables: UserEditFormRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserEditFormRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserEditFormFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserEditFormRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "jobBoardProfileLink",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "username",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "linkedin",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "github",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "location",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "website",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bio",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "image",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "organization",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "jobTitle",
                "storageKey": null
              },
              {
                "alias": "viewerCanDelete",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "action",
                    "value": "DELETE_USER"
                  }
                ],
                "kind": "ScalarField",
                "name": "viewerCan",
                "storageKey": "viewerCan(action:\"DELETE_USER\")"
              },
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isEntity"
              }
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "28aa5bc5315d039fb8fef8df99f7b6f1",
    "id": null,
    "metadata": {},
    "name": "UserEditFormRefetchQuery",
    "operationKind": "query",
    "text": "query UserEditFormRefetchQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...UserEditFormFragment\n    id\n  }\n}\n\nfragment EntityProfilePicFragment_db32b on Entity {\n  __isEntity: __typename\n  displayName\n  image\n}\n\nfragment UserEditFormFragment on User {\n  ...EntityProfilePicFragment_db32b\n  ...UserSettingIntegrationsFragment\n  id\n  displayName\n  username\n  linkedin\n  github\n  location\n  website\n  bio\n  image\n  email\n  organization\n  jobTitle\n  viewerCanDelete: viewerCan(action: DELETE_USER)\n}\n\nfragment UserSettingIntegrationsFragment on User {\n  id\n  jobBoardProfileLink\n}\n"
  }
};
})();

(node as any).hash = "6a331f8b134438c591ee986a0e368a7f";

export default node;
