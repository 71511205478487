/**
 * @generated SignedSource<<f24f798fe6c532890e680c06760ec8fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserEditFormFragment$data = {
  readonly bio: string | null | undefined;
  readonly displayName: string;
  readonly email: string;
  readonly github: string | null | undefined;
  readonly id: string;
  readonly image: any | null | undefined;
  readonly jobTitle: string | null | undefined;
  readonly linkedin: string | null | undefined;
  readonly location: string | null | undefined;
  readonly organization: string | null | undefined;
  readonly username: string;
  readonly viewerCanDelete: boolean;
  readonly website: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"EntityProfilePicFragment" | "UserSettingIntegrationsFragment">;
  readonly " $fragmentType": "UserEditFormFragment";
};
export type UserEditFormFragment$key = {
  readonly " $data"?: UserEditFormFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserEditFormFragment">;
};

import UserEditFormRefetchQuery_graphql from './UserEditFormRefetchQuery.graphql';

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": UserEditFormRefetchQuery_graphql,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "UserEditFormFragment",
  "selections": [
    {
      "args": [
        {
          "kind": "Literal",
          "name": "thumbnail",
          "value": false
        }
      ],
      "kind": "FragmentSpread",
      "name": "EntityProfilePicFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserSettingIntegrationsFragment"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "linkedin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "github",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "location",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "website",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bio",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "image",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "organization",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jobTitle",
      "storageKey": null
    },
    {
      "alias": "viewerCanDelete",
      "args": [
        {
          "kind": "Literal",
          "name": "action",
          "value": "DELETE_USER"
        }
      ],
      "kind": "ScalarField",
      "name": "viewerCan",
      "storageKey": "viewerCan(action:\"DELETE_USER\")"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "6a331f8b134438c591ee986a0e368a7f";

export default node;
