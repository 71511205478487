import { OperationType, graphql } from "relay-runtime";
import { UserSettingIntegrationsFragment$key } from "./__generated__/UserSettingIntegrationsFragment.graphql";
import { RefetchFnDynamic, useFragment, useMutation } from "react-relay";
import { useForm } from "react-hook-form";
import { UserSettingIntegrationsMutation as UserSettingIntegrationsMutationType } from "./__generated__/UserSettingIntegrationsMutation.graphql";
import Switch from "./Switch";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { toast } from "sonner";
import { OptionToggleCard } from "./OptionToggleCard";
import { UserEditFormFragment$key } from "./__generated__/UserEditFormFragment.graphql";
import { Link } from "react-router-dom";

const UserSettingIntegrationsMutation = graphql`
  mutation UserSettingIntegrationsMutation(
    $id: ID!
    $kind: JobSeekerProfileOptOperation!
  ) {
    toggleQuantumJobProfile(id: $id, kind: $kind)
  }
`;

const UserSettingIntegrationsFragment = graphql`
  fragment UserSettingIntegrationsFragment on User {
    id
    jobBoardProfileLink
  }
`;

interface FormData {
  isOptIn: boolean;
}

export interface Props {
  user: UserSettingIntegrationsFragment$key;
  refetch: RefetchFnDynamic<OperationType, UserEditFormFragment$key>;
}

const messages = defineMessages({
  registeredToast: {
    defaultMessage:
      "Your profile has been successfully registered on the Quantum Job Board.",
  },
  removedToast: {
    defaultMessage: "Your profile has been removed from the Quantum Job Board.",
  },
  registeredTitle: {
    defaultMessage: "Remove Your Profile from the Quantum Job Board",
  },
  removedTitle: {
    defaultMessage: "Register Your Profile on the Quantum Job Board",
  },
  registeredDescription: {
    defaultMessage:
      "Your profile is now live! You can view it here: <link>{url}</link>",
  },
  removedDescription: {
    defaultMessage:
      "Interested in quantum job opportunities? Visit the job board here: <link>{url}</link>",
  },
});

export function UserSettingIntegrations({
  user: userFragment,
  refetch,
}: Props) {
  const intl = useIntl();
  const { jobBoardProfileLink, id } = useFragment(
    UserSettingIntegrationsFragment,
    userFragment,
  );
  const registeredInJobBoard = Boolean(jobBoardProfileLink);
  const jobBoardUrl = import.meta.env.VITE_QUANTUM_JOBS;
  const url = registeredInJobBoard
    ? `${jobBoardUrl}${jobBoardProfileLink}`
    : jobBoardUrl;

  const { register, control } = useForm<FormData>();
  const [commitMutation, isMutationInFlight] =
    useMutation<UserSettingIntegrationsMutationType>(
      UserSettingIntegrationsMutation,
    );

  const { toastMessage, titleMessage, descriptionMessage } =
    getMessages(registeredInJobBoard);

  const description = (
    <FormattedMessage
      {...descriptionMessage}
      values={{
        url,
        link: (chunks) =>
          url && (
            <Link to={url} target="_blank" className="hover:text-indigo">
              {chunks}
            </Link>
          ),
      }}
    />
  );

  return (
    <OptionToggleCard
      title={intl.formatMessage(titleMessage)}
      description={description}
    >
      <Switch
        control={control}
        defaultValue={registeredInJobBoard}
        disabled={isMutationInFlight}
        {...register("isOptIn", {
          onChange({ target: { value } }) {
            commitMutation({
              variables: {
                id,
                kind: value ? "OPT_IN" : "OPT_OUT",
              },
              onCompleted: () => {
                refetch({}, { fetchPolicy: "network-only" });
                toast(intl.formatMessage(toastMessage));
              },
            });
          },
        })}
      />
    </OptionToggleCard>
  );
}

function getMessages(isRegistered: boolean) {
  return {
    toastMessage: isRegistered
      ? messages.registeredToast
      : messages.removedToast,
    titleMessage: isRegistered
      ? messages.registeredTitle
      : messages.removedTitle,
    descriptionMessage: isRegistered
      ? messages.registeredDescription
      : messages.removedDescription,
  };
}
