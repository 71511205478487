/**
 * @generated SignedSource<<8f8ab28d5c0a84612b6dbb6fecf00f74>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserSettingIntegrationsFragment$data = {
  readonly id: string;
  readonly jobBoardProfileLink: string | null | undefined;
  readonly " $fragmentType": "UserSettingIntegrationsFragment";
};
export type UserSettingIntegrationsFragment$key = {
  readonly " $data"?: UserSettingIntegrationsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserSettingIntegrationsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserSettingIntegrationsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jobBoardProfileLink",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "1b2da512a932f0540d8e225e73d6e588";

export default node;
