/**
 * @generated SignedSource<<44e4a0a1d2a80a19c1a6be9a4df1870b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type JobSeekerProfileOptOperation = "OPT_IN" | "OPT_OUT";
export type UserSettingIntegrationsMutation$variables = {
  id: string;
  kind: JobSeekerProfileOptOperation;
};
export type UserSettingIntegrationsMutation$data = {
  readonly toggleQuantumJobProfile: string;
};
export type UserSettingIntegrationsMutation = {
  response: UserSettingIntegrationsMutation$data;
  variables: UserSettingIntegrationsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "kind"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "kind",
        "variableName": "kind"
      }
    ],
    "kind": "ScalarField",
    "name": "toggleQuantumJobProfile",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserSettingIntegrationsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserSettingIntegrationsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8f227973a9c461ef8ace4cb02d39a662",
    "id": null,
    "metadata": {},
    "name": "UserSettingIntegrationsMutation",
    "operationKind": "mutation",
    "text": "mutation UserSettingIntegrationsMutation(\n  $id: ID!\n  $kind: JobSeekerProfileOptOperation!\n) {\n  toggleQuantumJobProfile(id: $id, kind: $kind)\n}\n"
  }
};
})();

(node as any).hash = "4ee21125f2bdc7ea7173aa3924f1e4a8";

export default node;
